import React from "react";
import ArticlesList from "../Articles/List";

const Home = ({ articlesData }) => (
  <section className="home-page">
    <ArticlesList articlesData={articlesData} />
  </section>
);

export default Home;
