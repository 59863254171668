import React from "react";
import { graphql } from "gatsby";
import Home from "../containers/Home";
import Layout from "../containers/Layout";
// get our fontawesome imports

const App = ({ data }) => {
  const {
    allStrapiArticles: { nodes: articlesData },
  } = data;
  return (
    <Layout>
      <Home articlesData={articlesData} />
    </Layout>
  );
};

export const query = graphql`
  {
    allStrapiArticles(sort: { fields: strapiId, order: DESC }, limit: 3) {
      nodes {
        published_at(formatString: "Do MMMM YYYY")
        original_published_at(formatString: "Do MMMM YYYY")
        slug
        excerpt
        id
        content
        strapiId
        title
      }
    }
  }
`;

export default App;
